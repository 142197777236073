$primary: #40B6BE;

$grey: #c4c4c4;

$black: #000000;
$black-2: #222;

$red: #d32f2f;

$white: #ffffff;

$text: #393533;

$break-sm: 768px;
$break-md: 992px;
$break-lg: 1220px;
$break-xl: 1660px;