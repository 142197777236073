@import "base";

body {
    font-family: 'Plus Jakarta Display', sans-serif;
    color: $text;
    background: linear-gradient(45deg, #dbdbdb, #ffffff);
    margin: 0;
}

#root{
    overflow-y: auto;
    height: 100vh;
}

.main-container {
    margin: auto;
    background-color: $white;
    max-width: calc(100% - 20px);
    text-align: center;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.content-container-bak {
  transition: 0.3s;
  display: flex;
  justify-content: center;
  max-width: 100%;
  margin: 100px auto;
  text-align: center;
  padding: 10px;
  height: -webkit-fill-available;
  border-radius: 8px;

}

.content-container{
  min-width: 1540px;
  min-height: 60%;
  padding: 20px;
}


.mui-shifted-label-input{
    .MuiInputLabel-root{
      margin-top: -25px;
      transform: scale(1);
      font-weight: inherit;
      color: $black !important;
      &.Mui-focused {
        color: $black;
      }
    }

    .Mui-error {
      margin-left: 0;
    }

    .MuiOutlinedInput-root {
      border: 1px solid $black;     
      border-radius: 0.5rem;

      &:hover {
        border-color: $primary;
      }
    }
    
    .MuiOutlinedInput-input{
      border:none;
    }
  
    .MuiOutlinedInput-notchedOutline{
      border: none;
    } 
}

.error {
  color: $red;
  font-size: 14px;
}

.link {
  font-weight: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.MuiDivider-root {
  margin: 10px 0 !important;
  border-width: 1px !important;
}
