.user-query-list {
  width: 30%;
  margin: 20px auto;
  font-family: 'Plus Jakarta Display', sans-serif;
  color: #333;
  background-color: #f7f9fc;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .query-text {
    font-size: 1.8rem !important;
    font-weight: bold !important;
    text-align: center;
    color: #444;
    margin-bottom: 16px;
  }

  .MuiList-root {
    border: none;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
  }

  .MuiListItem-root {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    background-color: #ffffff;
    align-items: center;
    margin-bottom: 12px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    transition: transform 0.2s, box-shadow 0.2s;

    &:hover {
      background-color: #f4f8fb;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
      transform: scale(1.02);
      cursor: pointer;
    }

    &:nth-child(even) {
      background-color: #f9fbfd;
    }

    .MuiListItemText-primary {
      font-weight: bold;
      color: #333;
      text-align: left;
      font-size: 1rem;
    }

    .MuiListItemText-secondary {
      font-size: 0.9rem;
      color: #666;
      text-align: left;
    }

    .MuiIconButton-root {
      color: #666;
      transition: color 0.2s;

      &:hover {
        color: #007bff;
        cursor: pointer;
      }

      &.edit-icon {
        color: #5cb85c;

        &:hover {
          color: #4cae4c;
        }
      }

      &.delete-icon {
        color: #d9534f;

        &:hover {
          color: #c9302c;
        }
      }
    }
  }

  .MuiDivider-root {
    margin: 0;
    background-color: #eaeaea;
  }

  .pagination {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    gap: 8px;
    list-style-type: none;
    padding: 0;

    li {
      button {
        padding: 8px 16px;
        border: none;
        background-color: #e9ecef;
        color: #333;
        cursor: pointer;
        border-radius: 4px;
        font-weight: bold;
        transition: background-color 0.2s;

        &:hover {
          background-color: #d6d8db;
        }

        &.active {
          background-color: #007bff;
          color: #fff;
        }

        &.disabled {
          background-color: #f7f7f7;
          color: #aaa;
          cursor: not-allowed;
        }
      }

      &.active button {
        background-color: #007bff;
        color: #fff;
      }
    }
  }
}
